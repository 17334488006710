import { type ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import NextLink from 'next/link'

import { type SanityTeamMember } from '@data/sanity/queries/types/blocks'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Photo from '@components/photo'
import { type IconName } from '@components/icon'
import IconButton from '@components/buttons/icon-button'
import ButtonLink from '@components/buttons/button-link'
import {
  ButtonIconAlignment,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

interface TeamMemberCardProps {
  teamMember: SanityTeamMember
  className?: string
}

interface PlaceholderImageProps {
  className?: string
}

const TeamMemberCard = ({ teamMember, className }: TeamMemberCardProps) => {
  const strings = useContext(StringsContext)

  const ref = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isModalOpen) {
        return
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsModalOpen(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [ref, isModalOpen])

  if (!teamMember) {
    return null
  }

  const { name, jobTitle, about, photo, email, linkedInUrl, blogAuthor } =
    teamMember

  const blogAuthorPageUrl = blogAuthor
    ? getLinkPageUrl('blogAuthor', blogAuthor.slug.current)
    : null
  const hasModal = about || linkedInUrl || blogAuthorPageUrl

  return (
    <div ref={ref} className={className}>
      <div className="flex flex-col relative">
        <button
          onClick={() => setIsModalOpen(true)}
          className={cx({
            'cursor-default': !hasModal,
          })}
        >
          {photo && <Photo image={photo} className="w-full" />}
          {!photo && <PlaceholderImage className="aspect-[2/3] bg-[#f5e6d0]" />}
        </button>

        {hasModal && (
          <AnimatePresence>
            {isModalOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 h-full flex flex-col py-4 px-2 -mb-8 bg-pageText bg-opacity-95 text-pageBG"
              >
                <div className="flex flex-col gap-y-4 mb-4 md:gap-y-8 md:mb-6 px-2">
                  <div className="flex justify-end">
                    <IconButton
                      icon="Cross"
                      variant={ButtonVariant.LINK}
                      size={ButtonSize.SMALL}
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>

                  {(linkedInUrl || blogAuthorPageUrl) && (
                    <div className="flex flex-col items-start gap-y-2">
                      {linkedInUrl && (
                        <Link href={linkedInUrl} openInNewTab>
                          {strings.teamMemberCardLinkedIn}
                        </Link>
                      )}

                      {blogAuthorPageUrl && (
                        <Link href={blogAuthorPageUrl}>
                          {strings.teamMemberCardBlogArticles}
                        </Link>
                      )}
                    </div>
                  )}
                </div>

                <p className="w-full text-base px-2 overflow-y-auto scrollbar-pageBG">
                  {about}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>

      <div className="flex flex-col items-start mt-3">
        <h5>{name}</h5>
        {jobTitle && <p className="font-light">{jobTitle}</p>}
        {email && (
          <ButtonLink
            href={`mailto:${email}`}
            className="font-light leading-[1.2]"
            variant={ButtonVariant.LINK}
          >
            {email}
          </ButtonLink>
        )}
      </div>
    </div>
  )
}

interface LinkProps {
  children: ReactNode
  href: string
  openInNewTab?: boolean
}

const Link = ({ children, href, openInNewTab }: LinkProps) => {
  const props = {
    className: 'text-sm md:text-base',
    variant: ButtonVariant.LINK,
    icon: 'ArrowRight' as IconName,
    iconAlignment: ButtonIconAlignment.LEFT,
  }

  if (openInNewTab) {
    return (
      <ButtonLink href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </ButtonLink>
    )
  }

  return (
    <NextLink passHref legacyBehavior href={href}>
      <ButtonLink {...props}>{children}</ButtonLink>
    </NextLink>
  )
}

const PlaceholderImage = ({ className }: PlaceholderImageProps) => (
  <div className={cx('w-full', className)} />
)

export default TeamMemberCard
